'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.users.factory:UserContextGroupManager

 # @description

###
angular
  .module 'mundoAdmin.users'
  .factory 'UserContextGroupManager', [
    'MundoDefaultManager'
    'UserRoleManager'
    'TenantManager'
    (
      MundoDefaultManager
      UserRoleManager
      TenantManager
    ) ->
      UserContextGroupManagerBase  = new MundoDefaultManager()

      UserContextGroupManagerBase.setUrl('user_contexts/groups')
      UserContextGroupManagerBase.setNewObject(['label', 'roles', 'userContextGroups', 'tenant'])
      UserContextGroupManagerBase.setUpdateObject(['label', 'roles', 'userContextGroups'])
      UserContextGroupManagerBase.setSearchFields(['label'])

      UserContextGroupManagerBase.new = (data) ->
        if (data.roles)
          data.roles = @extractIds(data.roles)

        if (data.userContextGroups)
          data.userContextGroups = @extractIds(data.userContextGroups)

        @transport.all(@getUrl()).post(_.pick(data, @getNewObject()))

      UserContextGroupManagerBase.update = (id, data) ->
        if (data.roles)
          data.roles = @extractIds(data.roles)

        if (data.userContextGroups)
          data.userContextGroups = @extractIds(data.userContextGroups)

        @transport.all(@getUrl()).one(id).customPUT(_.pick(data, @getUpdateObject()))

      UserContextGroupManagerBase.getNewForm = ->
        [
          key: 'label'
          name: 'label'
          type: 'input'
          templateOptions:
            label: 'Label'
            placeholder: 'label'
            required: true
            description: 'Descriptive text'
        ,
          key: 'roles'
          name: 'roles'
          type: 'modelchips'
          templateOptions:
            label: 'Roles'
            placeholder: 'roles'
            multiple: true,
            labelProp: "label"
            autocompleteLabel : 'add  or remove a role'
            valueProp: "id"
            options: UserRoleManager.getFullList().$object
        ,
          key: 'userContextGroups'
          name: 'userContextGroups'
          type: 'modelchips'
          templateOptions:
            label: 'User Context Groups'
            placeholder: 'User Context Groups'
            multiple: true,
            autocompleteLabel : 'add  or remove a user context group'
            labelProp: "label"
            valueProp: "id"
            options: @getList().$object
        ,
          key: 'tenant'
          name: 'tenant'
          type: 'select'
          templateOptions:
            label: 'Tenant'
            placeholder: 'tenant'
            required: true
            labelProp: "label"
            valueProp: "id"
            options: TenantManager.getFullList().$object
        ]
      UserContextGroupManagerBase.getEditForm = (data) ->
        [
          key: 'label'
          name: 'label'
          type: 'input'
          defaultValue: data.label
          templateOptions:
            label: 'Label'
            placeholder: 'label'
            description: 'Descriptive text'
        ,
          key: 'roles'
          name: 'roles'
          type: 'modelchips'
          defaultValue: data.roles
          templateOptions:
            label: 'Roles'
            placeholder: 'roles'
            multiple: true,
            labelProp: "label"
            autocompleteLabel : 'add  or remove a role'
            valueProp: "id"
            options: UserRoleManager.getFullList().$object
        ,
          key: 'userContextGroups'
          name: 'userContextGroups'
          type: 'modelchips'
          defaultValue: data.userContextGroups
          templateOptions:
            label: 'User Context Groups'
            placeholder: 'User Context Groups'
            multiple: true,
            labelProp: "label"
            autocompleteLabel : 'add  or remove a user context group'
            valueProp: "id"
            options: @getList().$object
        ]
      UserContextGroupManagerBase.getListSchema = ->
        [
          key: 'label'
          title: 'datatable.label.label'
          sort: 'label'
        ,
          key: 'roles.label'
          title: 'datatable.label.roles'
          multiple: 'roles'
        ]

      #permission needed to edit/delete an entity
      UserContextGroupManagerBase.editPermission = 'manage all MundoMosaUserBundle:UserContextGroup entities'

      UserContextGroupManagerBase
  ]
